<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1">Toimeksiantajat / omistajat</div>

    <v-card class="mt-2">
      <v-card-title>
        <v-spacer></v-spacer>
        <router-link style="text-decoration: none" to="/partner/create">
          <v-btn v-if="isAuthorized('partner', 'create')" color="info" class="mb-2"
            >Uusi toimeksiantaja</v-btn
          >
        </router-link>
      </v-card-title>

      <v-container>
        <v-row dense>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Hae kaikista kentistä..."
              single-line
              hide-details
              solo
              @keyup.enter="getDataFromApi()"
              @click:append="getDataFromApi()"
            ></v-text-field>
          </v-col>
          <v-col> </v-col>
        </v-row>
      </v-container>

      <v-data-table
        :headers="headers"
        :items="partners"
        :footer-props="globalValues.footerProps"
        :items-per-page="getDefaultTableRows()"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalLength"
      >
        <!-- Name -->
        <template #[`item.name`]="{ item }">
          <router-link style="text-decoration: none" :to="`/partner/overview/${item._id}`">{{
            item.name
          }}</router-link>
          <v-icon
            size="15"
            style="margin-bottom: 2px; margin-left: 4px"
            @click="openPartnerInfoDialog(item)"
            >mdi-eye-outline</v-icon
          >
        </template>

        <!-- Client id -->
        <template #[`item.clientId`]="{ item }">
          <span
            ><strong># {{ item.clientId }}</strong>
          </span>
        </template>

        <!-- Actions -->
        <template #[`item.actions`]="{ item }">
          <div class="action-grid-container">
            <v-icon
              v-if="isAuthorized('partner', 'edit')"
              title="Muokkaa"
              small
              @click="editItem(item)"
              >mdi-pencil</v-icon
            >
            <v-icon
              v-if="isAuthorized('partner', 'delete')"
              title="Poista"
              small
              @click="deleteItem(item)"
              >mdi-delete</v-icon
            >
          </div>
        </template>
        <template #no-data>
          <h2>Ei toimeksiantajia tai muita omistajia</h2>
        </template>
      </v-data-table>
    </v-card>

    <!-- Partner info dialog -->
    <partner-info-dialog v-model="partnerInfoDialog" sm="12" md="6" lg="6" />
  </div>
</template>

<script>
import mixins from "../../mixins/mixins";
import { mapActions, mapState, mapMutations } from "vuex";
import PartnerInfoDialog from "@/components/Partner/PartnerInfoDialog";
import globalValues from "@/configs/globalValues";

export default {
  title: "Toimeksiantajat / muut omistajat",

  mixins: [mixins],

  components: {
    PartnerInfoDialog,
  },

  data: () => ({
    loading: true,
    options: {},
    dialog: false,
    partnerInfoDialog: false,
    search: "",
    headers: [
      { text: "Asiakasnumero", value: "clientId" },
      { text: "Nimi", value: "name" },
      {
        text: "Henkilötunnus",
        value: "social",
      },
      {
        text: "Y-tunnus",
        value: "businessId",
      },
      {
        text: "Osoite",
        value: "address",
      },
      {
        text: "Postinumero",
        value: "zipCode",
      },
      {
        text: "Puhelin",
        value: "phone",
        sortable: false,
      },
      {
        text: "Email",
        value: "email",
      },
      {
        text: "Toiminnot",
        value: "actions",
        sortable: false,
      },
    ],
    globalValues,
  }),

  computed: {
    ...mapState("partner", ["partners", "totalLength"]),
    ...mapState("browsehistory", [
      "dataTablePage",
      "getPreviousPage",
      "itemsPerPage",
      "mainSearchValue",
    ]),
  },

  watch: {
    options: {
      handler() {
        if (this.getPreviousPage) {
          if (this.mainSearchValue != "") this.search = this.mainSearchValue;
          this.options.page = this.dataTablePage;
          this.options.itemsPerPage = this.itemsPerPage;
        }
        this.saveTableOptions({ ...this.options });
        this.getDataFromApi();
      },
      deep: true,
    },

    totalLength() {
      this.options.page = 1;
    },
  },

  methods: {
    ...mapActions("partner", ["getAllPartners", "inActivatePartner"]),
    ...mapMutations("partner", ["setCurrentPartner"]),
    ...mapMutations("browsehistory", ["saveTableOptions", "saveMainSearchTerm"]),

    async getDataFromApi() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      // Save main search term
      this.saveMainSearchTerm(this.search);

      let search = this.search.trim().toLowerCase();
      let url = `/api/v1/partner/getAll?sortBy=${sortBy}&sortDesc=${sortDesc}&page=${page}&itemsPerPage=${itemsPerPage}&search=${search}`;

      try {
        await this.getAllPartners(url);
      } catch (err) {
        this.showPopup(err, "error");
      }

      this.loading = false;
    },

    openPartnerInfoDialog(item) {
      this.setCurrentPartner(item);
      this.partnerInfoDialog = true;
    },

    editItem(item) {
      this.$router.push("/partner/edit/" + item._id);
    },

    deleteItem(item) {
      if (confirm("Haluatko varmasti poistaa kumppanin: " + item.name))
        this.inActivatePartner(item._id);
    },
  },
};
</script>

<style scoped></style>
